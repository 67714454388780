<template>
  <div class="container">
    <div class="ewm page-sub-box">
      <!--'bg-type01'삽입시 최상단 'page-sub-box'만 배경적용 -->
      <table class="table_form line-bin">
        <colgroup>
          <col style="width:130px;"/>
          <col style="width:auto;"/>
        </colgroup>
        <thead class="sub_title_txt">
        <tr>
          <td colspan="2"><h2>평가계획</h2></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">
            <label for="label01">평가유형 <span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <DxSelectBox
                :data-source="selectBoxDataSource.evalDivisionCd"
                :input-attr="{ 'aria-label': '평가유형' }"
                :styling-mode="config.stylingMode"
                v-model="formData.evalDivisionCd"
                display-expr="codeNm"
                value-expr="codeId"
                placeholder="평가유형 선택"
                :width="310"
                class="mr-4"
                @selection-changed="evalDivisionCdSelectionChange"
            >
              <DxValidator validation-group="evalValidate">
                <DxRequiredRule message="평가유형은 필수입니다."/>
              </DxValidator>
            </DxSelectBox>
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label01">평가계획명 <span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <DxTextBox
                v-model="formData.scheNm"
                :max-length="limitNumberTexts.maxLengths.scheNm"
                :styling-mode="config.stylingMode"
                class="mr-4"
                :show-clear-button="true"
                :width="310"
                @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'scheNm')"
            >
              <DxValidator validation-group="evalValidate">
                <DxRequiredRule message="평가계획명은 필수입니다."/>
              </DxValidator>
            </DxTextBox>
            {{ formData.scheNm ? formData.scheNm.length : 0 }}/{{ limitNumberTexts.maxLengths.scheNm }}자
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label01">평가표 <span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <div class="flex justify-start items-center">
              <DxTextBox
                  v-model="formData.sheetNm"
                  :styling-mode="config.stylingMode"
                  :width="277"
                  :read-only="true"
              >
                <DxValidator validation-group="evalSaveValidate">
                  <DxRequiredRule message="평가표는 필수입니다."/>
                </DxValidator>
              </DxTextBox>
              <DxButton
                  template="<span class='mdi mdi-magnify'></span>"
                  type="button"
                  hint="평가표 선택"
                  class="btn_XS white outlined"
                  :height="30"
                  @click="showSheetList"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label01">기준년월 <span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <div class="ui-datepicker period">
              <div class="ui-datepicker-item">
                <DxDateBox
                    v-model="formData.scheYmd"
                    :styling-mode="config.stylingMode"
                    :width="140"
                    type="date"
                    dateSerializationFormat="yyyy-MM-dd"
                    display-format="yyyy.MM"
                    placeholder="선택하세요"
                    :calendar-options="{
											zoomLevel: 'year',
											minZoomLevel: 'decade',
											maxZoomLevel: 'year'
										}"
                >
                  <DxValidator validation-group="evalSaveValidate">
                    <DxRequiredRule message="기준년월은 필수입니다."/>
                  </DxValidator>
                </DxDateBox>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label01">평가기간 <span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <div class="ui-datepicker period">
              <div class="ui-datepicker-item mr-2">
                <DxDateBox
                    v-model="formData.evalStartDt"
                    :styling-mode="config.stylingMode"
                    :width="140"
                    type="date"
                    dateSerializationFormat="yyyy-MM-dd"
                    display-format="yyyy.MM.dd"
                    placeholder="선택하세요"
                >
                  <DxValidator validation-group="evalSaveValidate">
                    <DxRequiredRule message="평가기간은 필수입니다."/>
                  </DxValidator>
                </DxDateBox>
              </div>
              <span class="dash">~</span>
              <div class="ui-datepicker-item ml-2">
                <DxDateBox
                    v-model="formData.evalEndDt"
                    :styling-mode="config.stylingMode"
                    :width="140"
                    type="date"
                    dateSerializationFormat="yyyy-MM-dd"
                    display-format="yyyy.MM.dd"
                    placeholder="선택하세요"
                    @value-changed="setEvalFeedbackStartDtMin"
                >
                  <DxValidator validation-group="evalSaveValidate">
                    <DxRequiredRule message="평가기간은 필수입니다."/>
                    <DxRangeRule
                        message="평가기간이 유효하지 않습니다."
                        :min="formData.evalStartDt ? formData.evalStartDt : valid.minDate"
                    />
                  </DxValidator>
                </DxDateBox>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label01">이의제기 기간 <span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <div class="ui-datepicker period">
              <div class="ui-datepicker-item mr-2">
                <DxDateBox
                    v-model="formData.evalFeedbackStartDt"
                    :styling-mode="config.stylingMode"
                    :width="140"
                    type="date"
                    dateSerializationFormat="yyyy-MM-dd"
                    display-format="yyyy.MM.dd"
                    placeholder="선택하세요"
                >
                  <DxValidator validation-group="evalSaveValidate">
                    <DxRequiredRule message="이의제기 기간은 필수입니다."/>
                    <DxRangeRule
                        message="이의제기기간은 평가기간 이후로 선택하세요."
                        :min="valid.evalFeedbackStartDtMin"
                    />
                  </DxValidator>
                </DxDateBox>
              </div>
              <span class="dash">~</span>
              <div class="ui-datepicker-item ml-2">
                <DxDateBox
                    v-model="formData.evalFeedbackEndDt"
                    :styling-mode="config.stylingMode"
                    :width="140"
                    type="date"
                    dateSerializationFormat="yyyy-MM-dd"
                    display-format="yyyy.MM.dd"
                    placeholder="선택하세요"
                >
                  <DxValidator validation-group="evalSaveValidate">
                    <DxRequiredRule message="이의제기 기간은 필수입니다."/>
                    <DxRangeRule
                        message="이의제기기간이 유효하지 않습니다."
                        :min="formData.evalFeedbackStartDt ? formData.evalFeedbackStartDt : valid.minDate"
                    />
                  </DxValidator>
                </DxDateBox>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <section class="terms bottom-btn-box">
      <div class="page-sub-box">
        <h2 class="hidden">일반 버튼</h2>
        <div class="bottom-btn-wrap">
          <DxButton text="저 장" class="default filled txt_S medium" :width="120" :height="40"
                    :disabled="formData.evalProcessCd !== 1182"
                    @click="onSaveFormData"/>
          <DxButton text="닫 기" class="btn_XS white filled txt_S medium" :width="120" :height="40"
                    @click="onCancelFormData"/>
        </div>
      </div>
    </section>

    <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
              :is="modal.currentComponent"
              :modalData="modal.sendData"
              v-model="modal.contentData"
          ></component>
        </div>
      </template>

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="modal.initData.hasOwnProperty('buttons')"
          :options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.text
							: ''
						: '',
					//type: 'default',
					width: '120',
					height: '40',
					useSubmitBehavior: true,
					onClick: e => {
						selectSheet(e);
					},
				}"
      />

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.hasOwnProperty('cancel')
							: !modal.initData.buttons.hasOwnProperty('cancel')
						: false
				"
          :options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						isOpenModal(false);
					},
				}"
      />
    </DxPopup>
  </div>
</template>

<script>
import {DxButton} from 'devextreme-vue/button';
import {DxDateBox} from 'devextreme-vue/date-box';
import {DxPopup, DxPosition, DxToolbarItem} from 'devextreme-vue/popup';
import {DxSelectBox} from 'devextreme-vue/select-box';
import {DxTextBox} from 'devextreme-vue/text-box';
import {DxValidator, DxRangeRule, DxRequiredRule} from 'devextreme-vue/validator';
import {formatDate, setCalculateDate, getPastFromToday, isEmpty} from '@/plugins/common-lib';

import ModalSheetList from '@/pages/ewm/evaluation/implementation/schedule/modal-sheet-list.vue';
import validationEngine from "devextreme/ui/validation_engine";

export default {
  components: {
    DxButton,
    DxDateBox,
    DxPopup,
    DxPosition,
    DxToolbarItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRangeRule,
    DxRequiredRule,
    ModalSheetList,
  },
  props: {},
  watch: {
    'modal.contentData': function (val) {
      if (!isEmpty(val.sheetId)) {
        this.formData.evalSheetId = val.sheetId;
      }
      if (!isEmpty(val.sheetId)) {
        this.formData.sheetNm = val.sheetNm;
      }
    },
  },
  data() {
    return {
      codeMap: {},
      reqParams: {},
      selectBoxDataSource: {
        evalDivisionCd: [],
      },
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
      },
      valid: {
        minDate: getPastFromToday(20, 'years'),
        evalFeedbackStartDtMin: getPastFromToday(20, 'years'),
      },
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      isCreatedData: false,
      formData: {
        id: null,
        evalDivisionCd: null,
        scheNm: null,
        evalSheetId: null,
        sheetNm: null,
        scheYmd: null,
        evalStartDt: null,
        evalEndDt: null,
        evalFeedbackStartDt: null,
        evalFeedbackEndDt: null,
        evalProcessCd: 1182,
        viewFl: this.$_enums.common.stringUsedFlag.YES.value,
        delFl: this.$_enums.common.stringUsedFlag.NO.value,
        regId: this.$store.getters.getLoginId,
      },
      limitNumberTexts: {
        textLengths: {},
        maxLengths: {
          scheNm: 85,
        },
      },
    };
  },
  computed: {},
  methods: {
    /** @description: 평가표 선택 */
    selectSheet() {
      const evalSheetId = this.formData.evalSheetId;
      if(!evalSheetId) {
        return this.$_Msg('대상이 선택되어 있지 않습니다.');
      }
      this.isOpenModal(false);
    },
    /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
    isOpenModal(isOpened) {
      this.modal.isOpened = isOpened;
      if (!isOpened) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
      }
    },
    /** @description: 팝업 창 열때 이벤트 */
    onOpenModal(componentNm, componentInitData, propsData) {
      this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
      this.modal.initData = componentInitData; //set init modal templet
      this.modal.sendData = propsData;

      this.isOpenModal(true);
    },
    evalDivisionCdSelectionChange() {
      if (!this.isCreatedData) {
        return;
      }
      this.formData.evalSheetId = null;
      this.formData.sheetNm = null;
    },
    /** @description: 평가표 목록 조회 */
    showSheetList() {
      const validationResult = validationEngine.validateGroup('evalValidate');
      if (!validationResult.isValid) {
        return;
      }

      const popData = {
        title: '평가표 현황',
        props: {
          parentId: this.formData.evalDivisionCd,
          evalDivisionCdDs: this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_eval_division']),
        },
      };

      this.onOpenModal(
          'ModalSheetList',
          {
            title: popData.title,
            buttons: {
              save: {text: '선택'},
              cancel: {text: '닫기'},
            },
            width: '700',
            height: '700',
          },
          popData.props,
      );
    },
    /** @description : 이의제기시작일 min값 설정 */
    setEvalFeedbackStartDtMin() {
      this.valid.evalFeedbackStartDtMin = this.formData.evalEndDt ?
          formatDate(setCalculateDate('day', this.formData.evalEndDt, 1), 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD') : this.valid.minDate;
    },
    /** @description : 저장 버튼 클릭 메서드 */
    async onSaveFormData(e) {
      const validationResult = validationEngine.validateGroup('evalValidate');
      if (!validationResult.isValid) {
        return;
      }
      const evalSaveValidate = validationEngine.validateGroup('evalSaveValidate');
      if (!evalSaveValidate.isValid) {
        return;
      }

      if (!await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {title: `<h2>알림</h2>`})) {
        return false;
      }

      const data = this.formData;

      let payload = {
        actionname: 'EWM_EVALUATION_SCHE_SAVE',
        data: data,
        loading: true,
      };

      let res = await this.CALL_EWM_API(payload);

      if (res.status === 200) {
        if (res.data.header.resCode === 'success') {
          this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));

          this.onCancelFormData();

        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      } else {
        this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      }

    },
    /** @description : 닫기 버튼 클릭 메서드 */
    onCancelFormData() {
      this.$router.push('/ewm/evaluation/implementation/schedule');
    },
    /** @description : 셀렉트 박스 초기화 */
    async initCodeMap() {
      this.codeMap = await this.$_getCodeMapList('root_ewm_eval_division');
    },
    /** @description: 라이프사이클 created시 호출되는 메서드 */
    async createdData() {
      this.reqParams = this.$store.getters.getDetailParams;
      if (!this.reqParams) {
        this.$_goPrePage();
        return;
      }

      if (!this.$_commonlib.isEmpty(this.reqParams)) {
        this.formData = this.$_commonlib.cloneObj(this.reqParams);
      }

      await this.initCodeMap().then(() => {
        this.selectBoxDataSource.evalDivisionCd = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 2);
      });

      this.isCreatedData = true;
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
  },
};
</script>
